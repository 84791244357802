import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
 // <Layout>
    <SEO title="Home" />
  //  <h3 style={{margin: '20px'}}></h3>
    
 // </Layout>
)

export default IndexPage